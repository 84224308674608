import React, {Component} from 'react'
import Layout from '../../components/layout';
import axios from 'axios';
import Loader from '../../components/loader.js';
import Helmet from 'react-helmet';
const API = process.env.API;
export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      minHeight: '',
      error: '',
      success: '', 
      loading: false
    }
  }
  checkScreenHeight() {
    this.setState({
      minHeight: window.innerHeight - 75
    })
  }
  componentDidMount() {
    this.checkScreenHeight();
    window.addEventListener('resize', this.checkScreenHeight.bind(this))
  }

  submitForm(e) {
    e.preventDefault();
    e.stopPropagation();
    const {email} = this.state;
    this.setState({loading: true, success: '', error: ''})
    // Request API.
    try{
    axios
      .post(`${API}/auth/forgot-password`, {email})
      .then(() => {
        // Handle success.
        this.setState({success: `An email has been to ${email}`, loading: false, error: ''})
      })
      .catch(error => {
        // Handle error.
        this.setState({error: 'Email address not found', loading: false, success: ''})
      });
    }
    catch {
      this.setState({error: 'An unknown error occurred', success: '', loading: false})
    }

  }
  render() {
    const {minHeight, error, success, loading} = this.state
    const textClass = 'text-sm p-3 border rounded-lg w-full border-gray-100 bg-white focus:border-2 focus:border-blue-300 focus:shadow-sm bg-white focus:outline-shadow transition-all duration-300'
    return (
      <Layout>
        <Helmet title="Forgot Password | New Image™ Head office" htmlAttributes={{lang: 'en'}}/>
        <div
          style={{
          minHeight: minHeight
        }}
          className="bg-blue-secondary relative flex items-center justify-content">
          <div className="max-w-sm p-10 shadow-xl bg-white rounded-lg mx-auto">
            <h1 className="text-3xl font-light text-brand-blue mb-4 text-center">Forgot Password</h1>
            <p>Fill in your email below and we will send you an email to reset your password.</p>
            <form
              className="mt-8"
              onSubmit={this
              .submitForm
              .bind(this)}>
              <label htmlFor="email" className="block text-sm my-2 text-brand-blue font-semibold">
                Email
              </label>
              <input
                name="email"
                id="email"
                required
                className={textClass}
                type="email"
                onChange={(({target}) => this.setState({email: target.value}))}/>
              <button id="submit"
                className="rounded-lg py-2 px-6 w-full bg-brand-blue text-white font-medium mt-4">Reset Password
                {loading&& <Loader/>}
                </button>
            </form>
            <div className="mt-4">
              {success && <div id="success-message"
                className="animation wiggle inline-block py-1 px-2 rounded border-l-4 border-green-700 bg-green-100 text-green-700">{success}</div>
}
              {error && <div id="error-message"
                className="animation wiggle inline-block py-1 px-2 rounded border-l-4 border-red-700 bg-red-100 text-red-700">{error}</div>
}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
